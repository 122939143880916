<template>
  <v-container>
    <div>
      <v-spacer></v-spacer>
      <v-layout row justify-center align-center v-if="save_disable">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="7"
          color="purple"
        ></v-progress-circular>
      </v-layout>

      <v-col class="text-right">
        <v-btn
          color="blue darken-1"
          class="ma-1"
          @click.stop="btn_accept"
          v-if="
            valider && test.statut_id == 6 && test.id > 0 && testtype_id != 1
          "
        >
          Accept
        </v-btn>
        <v-btn
          color="blue darken-1"
          class="ma-1"
          @click.stop="btn_reject"
          v-if="
            valider && test.statut_id == 6 && test.id > 0 && testtype_id != 1
          "
        >
          Return
        </v-btn>

        <v-btn
          color="blue darken-1"
          class="ma-1"
          @click.stop="validate_test"
          :disabled="changes > 1"
          v-if="
            editer &&
            (test.statut_id == 4 ||
              test.statut_id == 5 ||
              test.statut_id == 8) &&
            test.id > 0
          "
        >
          Validate
        </v-btn>
        <v-btn
          class="ma-1"
          color="blue darken-1"
          @click.stop="test_save"
          v-if="editer && (test.statut_id < 6 || test.statut_id == 8)"
          :disabled="save_disable || changes == 1"
        >
          Save
        </v-btn>
        <v-btn color="blue darken-1" @click.stop="close" class="ma-1">
          Close
        </v-btn>
      </v-col>
    </div>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showValidation"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-text>
            <br />

            {{ accept ? "Conclusion" : "Justification" }}
            <v-textarea
              ref="comment"
              outlined
              dense
              v-model="accept_comment"
              :rules="[
                (v) =>
                  !!v ||
                  (accept ? 'Conclusion' : 'Justification') + ' required',
              ]"
              row-height="30"
              rows="2"
              variant="outlined"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              v-if="accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="accept_test(7, 1)"
            >
              Accept
            </v-btn>
            <v-btn
              v-if="!accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="accept_test(8, 0)"
            >
              Return
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click.stop="dialog_close()"
              class="ma-1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="validation" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import SAVE_EVENT from "../graphql/WellTest/APM/SaveEvent.gql";
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    test: Object,
    testtype_id: Number,
    founds: Array,
    changes: Number,
    save_disable: Boolean,
    invalid_test_tubulars: Array,
  },

  data() {
    return {
      showValidation: false,
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      accept: false,
      valider: false,
      editer: false,
      accept_comment: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
    };
  },
  computed: {
    validate_ok() {
      let ctl = true;
      if (this.founds)
        this.founds.forEach((element) => {
          if (!element.asfound || !element.asleft) ctl = false;
        });
      return ctl;
    },
  },
  created() {
    this.editer =
      this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    dialog_close() {
      this.showValidation = false;
      this.$refs.form.resetValidation();
    },
    test_save() {
      this.$emit("test_save");
    },
    btn_accept() {
      this.$emit("accept_test", (result) => {
        // Parent component will pass the result of the validation
        if (result) {
          this.accept = true;
          this.showValidation = true;
        }
      });
    },
    btn_reject() {
      this.accept = false;
      this.showValidation = true;
    },
    async accept_test(test_statut) {
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.validation.open(
            "Confirmation",
            (test_statut == 7 ? "Accept" : "Reject") + " current test",
            { color: "orange darken-3" }
          )
        ) {
          let r = await this.$requette(
            SAVE_EVENT,
            {
              test_id: parseInt(this.test.id),
              test_type: this.testtype_id,
              test_statut_id: test_statut,
              event_statut_id: test_statut,
              user_id: this.$store.state.me.id,
              event_id: 2, // event returned
              comment: this.accept_comment,
              invalid_test_tubulars: this.invalid_test_tubulars
                ? this.invalid_test_tubulars
                : null,
            },
            "no-cache"
          );
          if (r.ok) {
            //this.test.statut_id = test_statut;
            this.showValidation = false;
            this.$emit("status_change", test_statut);
          }
        }
      }
    },
    async validate_test() {
      this.$emit("validate_test", (result) => {
        // Parent component will pass the result of the validation
        if (result) {
          this.validation();
        }
      });
    },
    async validation() {
      if (
        await this.$refs.validation.open(
          "Confirmation",
          "Validate this Test !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        let r = await this.$requette(
          SAVE_EVENT,
          {
            test_id: parseInt(this.test.id),
            user_id: this.$store.state.me.id,
            event_statut_id: 6,
            event_id: 2,
            test_statut_id: 6,
          },
          "no-cache"
        );
        if (r.ok) {
          this.$emit("status_change", 6);
        }
      }

      //else {
      //   this.snackbar_text = "As Test element missing";
      //   this.snackbar_color = "error";
      //   this.snackbar = true;
      // }
    },
  },
};
</script>
